import React from "react";
import { useIntl } from "react-intl";
import { Text } from "@shopify/polaris";
import styled from "styled-components";

type RiskDataRowProps = {
  label: string;
  value: number;
};

const RiskDataRow = (props: RiskDataRowProps) => {
  const { label, value, ...rest } = props;

  const { formatNumber } = useIntl();

  return (
    <StyledContainer {...rest}>
      <Text variant="bodyLg" as="span">
        {label}
      </Text>
      <Text variant="bodyLg" fontWeight="bold" as="span">
        {formatNumber(value)}
      </Text>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  gap: var(--p-space-4);
  padding: var(--p-space-4);
`;

export default RiskDataRow;
