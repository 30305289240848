import api from "../../api";

export const EMPTY_DASHBOARD_VALUES: api.DashboardResponse = {
  customers_approved_count: 0,
  customers_count_by_onboarding_status: {
    DONE: 0,
    FETCHING_DATA: 0,
    READY: 0,
    SCREENING_CONFIG: 0,
    SIGNATURE_CONFIG: 0,
    STARTED: 0
  },
  reviews_expired_count: 0,
  customers_active_count: 0,
  customers_pep_count: 0,
  customers_pep_count_by_customer_type: { PERSON: 0, COMPANY: 0 },
  customers_sanctioned_count: 0,
  customers_sanctioned_count_by_customer_type: { PERSON: 0, COMPANY: 0 },
  customers_with_pending_tasks_count: 0,
  customers_count_by_risk_level: { HIGH: 0, MEDIUM: 0, LOW: 0, NOT_SET: 0 },
  customers_count_by_customer_type: { PERSON: 0, COMPANY: 0 },
  customers_count_by_review_status: { APPROVED: 0, NOT_READY: 0, REVIEW_REQUIRED: 0, REJECTED: 0 },
  customers_count_by_industry_code: {
    metrics: [],
    categories: []
  },
  customers_count_by_risk_data: {
    HIGH_RISK_COUNTRY: 0,
    CONSTRUCTION_INDUSTRY: 0,
    HIGH_RISK_INDUSTRY: 0,
    PEP: 0,
    FINANCED_CRYPTO: 0,
    FINANCED_CROWDFUNDING: 0,
    MISSING_WORK_DOCUMENTATION: 0,
    MISSING_FUNDING_DOCUMENTATION: 0
  },
  assignments_count_by_risk_data: {
    HIGH_RISK_COUNTRY: 0,
    CONSTRUCTION_INDUSTRY: 0,
    HIGH_RISK_INDUSTRY: 0,
    PEP: 0,
    FINANCED_CRYPTO: 0,
    FINANCED_CROWDFUNDING: 0,
    MISSING_WORK_DOCUMENTATION: 0,
    MISSING_FUNDING_DOCUMENTATION: 0
  }
};
